.commonCardContainer {
   height: 161px;
   padding: 19px 16px 19px 16px;
   border-radius: 8px;
   gap: 12px;
   box-shadow: 0px 0px 4px 0px #1018281A;
   border: 1px solid #C2C2C2;
   background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #C2C2C2, #C2C2C2);
}

.historyCardContainer {
   height: 120px !important;
}

.commonCardHeaderContainer {
    display: flex;
    align-items: flex-start;
    height: 71px;
    gap: 12px;
}

.historyCardContainer {
   .commonCardHeaderContainer {
      height: 55px;
   }
}

.commonCardHeaderText {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;
}

.commonCardImage {
    width: 28px;
}

.commonCardFooterText {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;
    display: flex;
    align-items: center;
    justify-content: center;
}

.powerCapacityCard {
    display: flex;
    height: 161px;
    border-radius: 8px;
    border: 2px;
    background: #FFFCF7;
    border: 2px solid #FF9800;
    box-shadow: 0px 0px 4px 0px #1018281A;
}

.borderColorChange{
    border:2px solid rgba(231, 34, 34, 1);
    background: #FFF7F7
}

.powerCardContainer {
    width: 100%;
    padding: 20px 12px 20px 12px;
    gap: 12px;
    border-right: 1px solid #EAECF0;
}

.CapacityCardContainer {
    width: 100%;
    padding: 20px 16px 20px 16px;
    border: 1px;
    gap: 12px;
}

.potentialDescContainer {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 8px 0px;
    gap: 10px;
    border-bottom: 1px solid #E6E6E6;
    margin-top: 12px;
}

.potentialText {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #051433
}

.potentialNumberText {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #051433;
}

.potentialTextAndNumberContainer {
    display: flex;
    align-items: center;
    gap: 15px;
}

.potentialCardHeaderContainer {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

@media screen and (max-width:1720px) {
    .potentialTextAndNumberContainer {
        gap: 2px;
    }

    .potentialText {
        font-size: 12px;
        line-height: 20px;
    }

    .potentialNumberText {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width:1600px) {
    .commonCardContainer {
        height: 176px;
        padding: 16px;
    }

   .commonCardHeaderContainer {
      height: 104px;
   }
   
    .historyCardContainer {
      .commonCardHeaderContainer {
         height: 65px;
      }
    }

    .commonCardHeaderText {
        font-size: 18px;
        line-height: 28px;
    }

    .commonCardFooterText {
        font-size: 24px;
        line-height: 28.13px;
    }

    .powerCapacityCard {
        height: 176px;
    }

    .powerCardContainer {
        padding: 16px 12px 16px 12px;
    }

    .CapacityCardContainer {
        padding: 16px 11px 16px 11px;
    }

    .potentialDescContainer {
        margin-top: 8px;
        display: block;
    }

    .potentialTextAndNumberContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
    }

    .potentialCardHeaderContainer {
        display: flex;
        align-items: flex-start;
        gap: 12px;
    }
}

@media screen and (max-width:1440px) {
    .commonCardHeaderText {
        font-size: 16px;
        line-height: 24px;
    }

    .commonCardFooterText {
        font-size: 22px;
        line-height: 24.13px;
    }

    .commonCardHeaderContainer {
        gap: 10px;
    }
}

@media screen and (max-width: 1280px) {
    .commonCardContainer {
      height: 150px;
      padding: 10px;
    }

    .commonCardHeaderText {
        font-size: 12px;
        line-height: 20px;
        margin-top: 5px;
    }

    .commonCardHeaderContainer {
        height: 90px;
    }

    .commonCardFooterText {
        font-size: 20px;
        line-height: 24.13px;
    }

    .powerCapacityCard {
        height: 150px;
    }

    .powerCardContainer {
        padding: 10px;
    }

    .CapacityCardContainer {
        padding: 8px 9px;
    }

    .potentialDescContainer {
        margin-top: 2px;
        display: block;
    }

    .potentialTextAndNumberContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .potentialText {
        font-size: 10px;
        line-height: auto;
    }

    .potentialNumberText {
        font-size: 14px;
        line-height: auto;
    }
}