.page{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #fafafa;
    background-size: cover;
    will-change: transform;
}

.pageHeader{
   position: relative;
}

.pageBody{
    position: relative;
    margin: 16px 8px 0;
    overflow: scroll;
    flex-grow: 1;
    &NoScroll{
        overflow: unset;
    }
}

.pageContent{
    position: relative;
    background: #fff;
    min-height: 100%;
}

@media screen and (max-width:1600px) {
    .pageBody{
        position: relative;
        margin: 12px 6px 0;
        overflow: scroll;
        flex-grow: 1;
        &NoScroll{
            overflow: unset;
        }
    }
}


@media screen and (max-width:1366px) {
    .pageBody{
        position: relative;
        margin: 8px 4px 0;
        overflow: scroll;
        flex-grow: 1;
        &NoScroll{
            overflow: unset;
        }
    }
}