@import './../../../../assets/scss/base/base.scss';

.toggleButton{
  &Item{
    &:global(.MuiToggleButton-root.Mui-selected),&:global(.MuiToggleButton-root.Mui-selected:hover){
      background-color: $brand-primary;
      color: #fff;
    }

    &:global(.MuiToggleButton-root.Mui-selected.Mui-disabled),&:global(.MuiToggleButton-root.Mui-selected.Mui-disabled:hover){
      background-color: #bdbdbd;
      color: #fff;
    }
  }

  &_Switch{
    display: grid!important;
    grid-template-columns: 1fr 1fr;
    .toggleButtonItem{
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
      &:global(.MuiToggleButton-root.Mui-selected),&:global(.MuiToggleButton-root.Mui-selected:hover){
        background-color:#2196F3;
        color: #fff;
      }

      &:global(.MuiToggleButton-root),&:global(.MuiToggleButton-root:hover){
        background-color: $brand-primary;
        color: #fff;
      }
  
      &:global(.MuiToggleButton-root.Mui-selected.Mui-disabled),&:global(.MuiToggleButton-root.Mui-selected.Mui-disabled:hover){
        background-color: #bdbdbd;
        color: #fff;
      }
    }
  }
}