.dialog{
    &Title{
       border-bottom: solid 1px rgba(0, 0, 0, 0.87);
    }

    &Body{
        &:global(.MuiDialogContentText-root){
            font-size: 14px;
            color: rgba(0, 0, 0, 0.87);
        }
    }

    :global(.MuiDialogContent-root){
        padding-bottom: 0;
    }

    :global(.MuiDialogActions-root){
        padding-top: 15px;
        padding-bottom: 15px;
    }
}