*{
    margin:0;
    padding:0;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

ul,li{
  list-style: none;
  padding: 0;
  margin: 0;
}

html { 
  height: 100%; 
}
body { 
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif; 
  color: #000;
  line-height: normal;
}

img[src=""] {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #aaa;
}

::-webkit-scrollbar-track {
  background: #fafafa;
}

a:hover,a:focus{
  text-decoration: none!important;
}