.inlineLoader:first-child{
    display: block;
    position: relative;
    left: 0px;
    top: 0px;
    width: 24px;
    height: 24px;
    margin: 0px 0 0 0px;
    border-radius: 50%;
    box-shadow: 0 2px 2px 0;
    transform: translate3d(0, 0, 0);
    animation: spin 1.5s linear infinite;

    &:after{
        content: "";
        position: absolute;
        border-radius: 50%;
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
        box-shadow: 0 2px 2px 0;
        animation: spin 1s linear infinite;
    }
}


@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media screen and (max-width:1366px) {
.inlineLoader:first-child{
  width: 15px;
  height: 15px;
}
  
}