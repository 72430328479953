.semiCircularContainer {
    position: relative;
    width: 100px;
    height: 40px !important;
}

.unfilledProgress {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-180deg);
    height: 100px !important;
}

.filledProgress {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-180deg);
    margin-bottom: 5px;
    height: 100px !important;
}

.percentageText {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    color: #000;
}

.CenterText {
    position: absolute;
     top: 70% !important;
     left: 50%;
}

@media screen and (max-width: 1720px) {
    .semiCircularContainer {
        width: 100px;
        height: 40px;
    }
}

@media screen and (max-width: 1500px) {
    .semiCircularContainer {
        height: 30px !important;
    }

    .CenterText {
        position: absolute;
         top: 90% !important;
         left: 50%;
    }
}