.datetimeRangePicker {
  .rs-picker-toggle-textbox {
    padding-right: 14px;
    background-color: inherit !important; 
    display: none;
  }

  .rs-picker-toggle {
    --rs-picker-value: #000;
    border: none;
    border-bottom: 1px solid #000 !important;
    border-radius: 0;
    padding: 7px 0;
    font-size: 15.8px;
    background-color: inherit !important; 
  }
  @media screen and (max-width: 1440px) {
    .rs-picker-toggle {
      --rs-picker-value: #000;
      border: none;
      border-bottom: 1px solid #000 !important;
      border-radius: 0;
      padding: 7px 0;
      font-size: 12px;
      background-color: inherit !important; 
    }
  }

  .rs-picker-toggle-active,
  .datetimeRangePicker .rs-picker.rs-picker-focused {
    box-shadow: none;
  }

  .rs-picker-toggle-textbox {
    padding-left: 0;
  }

  .rs-picker-toggle-caret {
    right: 0 !important;
    color: #000;
  }

  .rs-picker-toggle-caret .rs-icon {
    color: #000;
  }
}

.rs-picker-daterange-menu .rs-btn-link {
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 12px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 12px;
  line-height: 13.5px;
  width: 100%;
}

.rs-picker-daterange-menu {
  --rs-bg-active: #1976d2;
  --rs-btn-primary-bg: #1976d2;

  .rs-btn-link {
    &:hover {
      text-decoration: none;
      border-radius: 4px;
      background-color: #e8e8e8;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .rs-stack-item {
    width: 100%;
  }

  .rs-picker-daterange-predefined.rs-stack {
    padding: 4px;
  }
}

.rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-daterange .rs-picker-toggle.rs-btn {
  padding-right: 14px;
}

.rs-picker-daterange-header {
  display: none;
}
.rs-picker-toolbar.rs-stack {
  text-align: right;
}
.rs-calendar-header-time-toolbar {
  display: block;
  position: absolute;
  bottom: 0;
  margin: 0 0 0 39px;
  height: 35px;
}
.rs-picker-daterange-menu .rs-calendar {
  height: 314px;
}
.rs-calendar-header-title,
button.rs-calendar-header-meridian.rs-btn.rs-btn-subtle.rs-btn-xs {
  font-size: 19px;
  color: #000;
}
.rs-picker-toolbar.rs-stack {
  margin-top: 40px;
}
button.rs-btn.rs-btn-primary.rs-btn-sm {
  color: #fff;
}
button.rs-btn.rs-btn-primary.rs-btn-sm:hover {
  color: #fff;
}
button.rs-btn.rs-btn-primary.rs-btn-sm:after {
  color: #fff;
  padding-right: 6px;
  margin-left: -12px;
}
button.rs-btn.rs-btn-primary.rs-btn-sm::before {
  content: '';
}
.rs-picker-daterange-panel > .main_mainBody__tl65o {
  overflow-y: hidden !important;
}
span.rs-calendar-table-header-cell-content {
  font-weight: bold;
  color: #000;
}
.rs-picker-menu .rs-calendar .rs-calendar-time-dropdown-column > ul {
  padding-bottom: 40px;
}

.rs-picker-toggle-wrapper {
  width: 100% !important;
}
