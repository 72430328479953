
.flipCard {
    background-color: transparent;
    width: 100%;
    height: 280px;
    perspective: 1000px;
    border-radius: 6px;
    overflow: hidden;

    &Inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        border-radius: 6px;
    }

    &:hover .flipCardInner {
        transform: rotateY(180deg);
    }

    .flipCardFront, .flipCardBack {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        border-radius: 6px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .flipCardFront {
        color: black;
        background-color: #fafafa;
    }
      
    .flipCardBack{
        background-color: rgba(0,0,0,.5);
        color: white;
        transform: rotateY(180deg);
        display: flex;
        align-items: center;
        justify-content: center;

        :global(.MuiButtonBase-root) {
            margin: 0 10px;
        }

        &Input{
          display: none;
        }
    }
}
  
//   .flipCardInner {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     text-align: center;
//     transition: transform 0.6s;
//     transform-style: preserve-3d;
//     box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
//     border-radius: 6px;
//   }
  
//   .flip-card:hover .flip-card__inner {
//     transform: rotateY(180deg);
//   }
  
//   .flip-card__front, .flip-card__back {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     -webkit-backface-visibility: hidden;
//     backface-visibility: hidden;
//     border-radius: 6px;
//   //  background-image: url('./../../img/theme/no-image.png');
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-position: center;
//   }
  
  .flip-card__front {
    color: black;
  }
  
  .flip-card__back {
    background-color: rgba(0,0,0,.5);
    color: white;
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiButtonBase-root {
      margin: 0 10px;
    }
  }