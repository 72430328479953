@import './base/functions';
@import './base/mixins/mixins';
@import './base/variables';
@import './base/reset';

@import './utils/spacing';
@import './utils/typography';

@import './overrides/mui';

// To make map dropdown content visible on popups
.pac-container {
    z-index: 1600;
}

.grecaptcha-badge { visibility: hidden; }

.information{
    line-height: 20px;
}
.information_block{
    margin-top: 30px;
}

@media screen and (max-width: 1600px) {
    .responsiveImage{
        width:35px !important;height: 35px !important;
    }
    .smartChargerImage{
        width:24px;
        height:24px;
    }
    .demandViewSmartChargingIcon{
        height: 24px !important;
        width: 24px !important;
    }
    .chargerConfigurationImage{
        height: 20px ;
        width: 20px;
    }
    .commonimageicons{
        height: 14px !important;
        width: 14px !important;
    }
}
@media screen and (max-width: 1366px) {
    .responsiveImage{
        width:23px !important;height: 23px !important;
    }

    .chargerConfigurationImage{
        height: 18px ;
        width: 18px;
    }
    .commonimageicons{
        height: 12px !important;
        width: 12px !important;
    }
}
#standard-search {
    cursor: text;
}

@media screen and (max-width: 1400px) {
    .smartChargerImage{
        width:24px;
        height:24px;
    }
    .commonimageicons{
        height: 14px ;
        width: 14px;
    }
}