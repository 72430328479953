.paginationContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 32px;
    height: 56px !important;
    align-self: stretch;
    border-top: 1px solid #E6E6E6;
    background: #FFFFFF;
    font-size: 18px;
    position: sticky;
    bottom: 0;
    z-index: 100;
    margin-top: 1px;
}

.paginationStyles {
    width: 100% !important;
    border-radius: 9px !important;
    padding: 0px 24px !important;
    gap: 32px !important;
    color: #242424 !important;
    font-size: 16px !important;
    font-family: Roboto !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    border: none !important;
    box-shadow: none !important;

    .MuiTablePagination-toolbar {
        min-height: 56px !important;
    }

    .MuiTablePagination-selectLabel,
    .MuiTablePagination-displayedRows {
        font-size: 16px !important;
    }

    .MuiTablePagination-select {
        margin-top: 3px !important;
        padding-left: 0px !important;
    }

    .MuiTablePagination-selectIcon {
        font-size: 24px !important;
        margin-top: 1px !important;
    }
}

@media screen and (max-width:1720px) {
    .paginationContainer {
        height: 44px !important;
        gap: 20px !important;
    }

    .paginationStyles {
        padding: 0px 24px !important;
        font-size: 12px !important;
        gap: 20px !important;

        .MuiTablePagination-toolbar {
            min-height: 40px !important;
        }

        .MuiTablePagination-actions {
            margin-left: 20px !important;
        }

        .MuiInputBase-colorPrimary{
            margin-right: 20px !important;
        }

        .MuiTablePagination-selectLabel {
            font-size: 14px !important;
        }

        .MuiTablePagination-displayedRows {
            font-size: 14px !important;
        }
    }


    .MuiTablePagination-select {
        margin-top: 3px !important;
        padding-left: 0px !important;
    }


    .MuiTablePagination-selectIcon {
        font-size: 24px !important;
        margin-top: 1px !important;
    }
}

@media screen and (max-width:1600px) {
    .paginationContainer {
        height: 40px !important;
        gap: 20px !important;
    }

    .paginationStyles {
        padding: 0px 24px !important;
        font-size: 12px !important;
        gap: 20px !important;

        .MuiTablePagination-toolbar {
            min-height: 40px !important;
        }

        .MuiTablePagination-actions {
            margin-left: 20px !important;
        }

        .MuiInputBase-colorPrimary{
            margin-right: 20px !important;
        }

        .MuiTablePagination-selectLabel {
            font-size: 14px !important;
        }

        .MuiTablePagination-displayedRows {
            font-size: 14px !important;
        }
    }


    .MuiTablePagination-select {
        margin-top: 3px !important;
        padding-left: 0px !important;
    }


    .MuiTablePagination-selectIcon {
        font-size: 24px !important;
        margin-top: 1px !important;
    }
}

@media screen and (max-width:1440px) {
    .paginationContainer {
        height: 28px !important;
        gap: 20px !important;
    }

    .paginationStyles {
        padding: 0px 24px !important;
        font-size: 10px !important;
        gap: 20px !important;

        .MuiTablePagination-toolbar {
            min-height: 28px !important;
        }

        .MuiTablePagination-actions {
            margin-left: 16px !important;
        }

        .MuiInputBase-colorPrimary{
            margin-right: 16px !important;
        }

        .MuiTablePagination-selectLabel,
        .MuiTablePagination-displayedRows {
            font-size: 10px !important;
        }
    }

    .MuiTablePagination-select {
        margin-top: 3px !important;
        padding-left: 0px !important;
    }

    .MuiTablePagination-selectIcon {
        font-size: 20px !important;
        margin-top: 1px !important;
    }

    .MuiTablePagination-actions .MuiButtonBase-root .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
    }
}

@media screen and (max-width:1280px) {
    .paginationContainer {
        height: 26px !important;
        gap: 18px !important;
    }

    .paginationStyles {
        padding: 0px 22px !important;
        font-size: 8px !important;
        gap: 18px !important;

        .MuiTablePagination-toolbar {
            min-height: 26px !important;
        }

        .MuiTablePagination-actions {
            margin-left: 14px !important;
        }

        .MuiInputBase-colorPrimary{
            margin-right: 14px !important;
        }

        .MuiTablePagination-selectLabel,
        .MuiTablePagination-displayedRows {
            font-size: 8px !important;
        }
    }

    .MuiTablePagination-select {
        margin-top: 2px !important;
        padding-left: 0px !important;
    }

    .MuiTablePagination-selectIcon {
        font-size: 18px !important;
        margin-top: 1px !important;
    }

    .MuiTablePagination-actions .MuiButtonBase-root .MuiSvgIcon-root {
        width: 18px;
        height: 18px;
    }
}