.errorPageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 5%;
}

.errorPageHeaderText {
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    width: 592px;
}


.errorPageDescText {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    width: 404px;
}

.errorPageBackButton {
    padding: 10px 16px;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    background: #3D3AD3;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
}


.errorPageFirstConnector {
    position: fixed;
    bottom: 12%;
    right: 0;
}

.errorPageSecondConnector {
    position: fixed;
    bottom: 0;
    left: 0;
}

@media screen and (max-width: 1720px) {
    .errorPageContainer {
        margin-top: 5%;
    }

    .errorPageHeaderText {
        font-size: 45px;
        width: 550px;
    }


    .errorPageDescText {
        font-size: 18px;
        width: 404px;
    }


    .errorPageFirstConnector {
        width: 350px;
        height: 450px;
        bottom: 12%;
    }

    .errorPageSecondConnector {
        width: 550px;
        height: 250px;
        bottom: 0;
        left: 0;
    }
}

@media screen and (max-width: 1440px) {
    .errorPageContainer {
        margin-top: 2%;
        gap: 12px;

    }

    .errorPageHeaderText {
        font-size: 35px;
        width: 450px;
        line-height: 45px;
    }


    .errorPageDescText {
        font-size: 14px;
        width: 300px;
        line-height: 24px;
    }

    .errorPageBackButton {
        padding: 8px 14px;
        font-size: 10px;
        gap: 6px;
    }
    

    .errorPageFirstConnector {
        width: 300px;
        height: 400px;
        bottom: 10%;
    }

    .errorPageSecondConnector {
        width: 500px;
        height: 200px;
        bottom: 0;
        left: 0;
    }

}


@media screen and (max-width: 1280px) {
    .errorPageContainer {
        margin-top: 2%;
        gap: 10px;

    }

    .errorPageHeaderText {
        font-size: 30px;
        width: 400px;
        line-height: 40px;
    }


    .errorPageDescText {
        font-size: 12px;
        width: 250px;
        line-height: 24px;
    }

    .errorPageBackButton {
        padding: 6px 12px;
        font-size: 10px;
        gap: 6px;
    }


    .errorPageFirstConnector {
        width: 300px;
        height: 400px;
        bottom: 10%;
    }

    .errorPageSecondConnector {
        width: 500px;
        height: 200px;
        bottom: 0;
        left: 0;
    }
}