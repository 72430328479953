.button{
    &:global(.MuiButtonBase-root){
        text-transform: capitalize;
        font-weight: 400;
    }
    @media screen and (max-width: 1600px) {
        padding: 0px !important;
        width: 20px !important;
        height: 20px !important;
    }
}