.content {
  height: 100%;
  display: flex;
  flex-direction: column;

  &Top {
    padding: 0 0px;
  }

  &TopPrice {
    background: #f5f5f5;
    padding: 0;
  }

  &Body {
    position: relative;
    padding-top: 15px;
    flex-grow: 1;
    overflow: auto;
  }

  &BodyPrice {
    position: relative;
    padding-top: 15px;
    padding-bottom: 12px;
    flex-grow: 1;
    overflow: auto;
  }

  &BodyCharging {
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    overflow-y: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
