@mixin nineteen-down-device {
  @media (min-width: 2200px) {
    @content;
  }
}
@mixin seventeen-down-device {
  @media (min-width: 1600px) and (max-width: 2200px) {
    @content;
  }
}
@mixin fifteen-down-device {
  @media (max-width: 1600px) {
    @content;
  }
}

@mixin xxl-down-device {
  @media (max-width: 1399px) {
    @content;
  }
}

@mixin xl-down-device {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin xxl-device {
  @media (min-width: 1400px) and (max-width: 1599px) {
    @content;
  }
}

@mixin xl-device {
  @media (min-width: 1200px) and (max-width: 1399px) {
    @content;
  }
}

@mixin lg-device {
  @media (min-width: 992px) and (max-width: 1200px) {
    @content;
  }
}

@mixin lg-up-device {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin lg-down-device {
  @media (max-width: 991px) {
    @content;
  }
}
