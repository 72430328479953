@import './../../../../assets/scss/base/base.scss';

.sidebar {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 80px;
  overflow: hidden;
  transition: all 0.3s;
  @include shadow-big();

  &Open {
    width: 235px;
  }

  &Top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0 10px 0;

    &Open {
      flex-direction: row;
      align-items: center;
    }
  }

  &Bottom {
    transition: all 0.3s;
  }

  &Hamburger {
    padding-left: 20px;
  }

  &Logo {
    position: relative;
    display: flex;
    justify-content: center;
    &Img {
      max-height: 50px;
      max-width: 50px;
      // margin: 8px;
      &Open {
        max-height: 100px;
        max-width: 150px;
      }
    }
  }
}
.sidebar_scroll {
  height: calc(100vh - 140px);
  overflow: auto;
}

@media screen and (max-width: 1700px) {
  .sidebar {
    &Open {
      width: 200px;
    }
    &Top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 0 10px 0;

      &Open {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .sidebar_scroll {
    height: calc(100vh - 140px);
    overflow: auto;
  }
}
@media screen and (max-width: 1600px) {
  .sidebar {
    &Open {
      width: 195px;
    }
    &Top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 0 10px 0;

      &Open {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .sidebar_scroll {
    height: calc(100vh - 140px);
    overflow: auto;
  }
}

@media screen and (max-width: 1600px) {
  .sidebar {
    &Open {
      width: 190px;
    }

    &Top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 0 10px 0;

      &Open {
        flex-direction: row;
        align-items: center;
      }
    }

    &Logo {
      position: relative;
      display: flex;
      justify-content: center;
      &Img {
        margin: 8px;
        &Open {
          max-height: 75px;
          max-width: 85px;
        }
      }
    }
  }

  .sidebar_scroll {
    height: calc(100vh - 140px);
    overflow: auto;
  }
}

@media screen and (max-width: 1366px) {
  .sidebar {
    &Open {
      width: 160px;
    }

    &Top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px;

      &Open {
        flex-direction: row;
        align-items: center;
      }
    }

    &Logo {
      position: relative;
      display: flex;
      justify-content: center;
      &Img {
        margin: 8px;
        &Open {
          max-height: 75px;
          max-width: 85px;
        }
      }
    }
  }

  .sidebar_scroll {
    height: calc(100vh - 70px);
    overflow: auto;
  }
}

@media screen and (max-width: 1200px) {
  .sidebar_scroll {
    height: calc(100vh - 120px);
    overflow: auto;
  }
}
