@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

$blue600: #1E88E5;
$red600: #E53935;
$green600: #43A047;
$grey600: #757575;
$orange600: #FB8C00;
$blue200: #90CAF9;

.detailConnectorContainer {
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}
.detailConnector {
  align-items: center !important;
  display: flex;
  min-width: 58px;
  margin-right: -10px !important;
}

.chargerConnectorContainer {
  text-align: center;
  width: 30px;
  height: 30px;
  font-size: 1.1rem;
  font-weight: bold;
  justify-content: cente !important;
  align-items: center;
  z-index: 1;
  color: white;

  &.chargerConnectorContainerImage {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 2px solid darken($blue600, 15%);

    &.chargerConnectorContainerImageSmall {
      left: -15px;
      transition: all .2s ease-in-out;
      z-index: 0;
      position: relative;
    }
  }

  &.chargerConnectorContainerImageSmall:hover {
    transform: scale(1.43);
    z-index: 2;
  }

  &.chargerConnectorContainerImageLarge {
    margin-left: 4px;
  }

  &.connectorNotTypedIcon {
    color: $red600;
  }

  .chargerConnectorBackground {
    border-radius: 50%;
    width: 100%;
    height: 100%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.chargerConnectorBackgroundSpinner:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: spinner 3s linear infinite;
      border-radius: 50%;
      border-left: 3px solid $blue200;
      border-top: 3px solid $blue600;
      border-right: 3px solid $blue200;
      border-bottom: 3px solid $blue600;
      z-index: -1;
    }
  }

  .chargerConnectorText {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
    position: relative;
    // top: -30px;
    opacity: 1 !important;
    z-index: 0;
  }

  .chargerConnectorAvailable {
    color: $green600;
    border: 2px solid darken($green600, 5%);
    background-color: white;
  }

  .chargerConnectorChargingAvailableText {
    color: $green600;
  }

  &.chargerConnectorTypeBackground {
    border: 1px solid $grey600;
    background-color: lighten($grey600, 20%);
  }

  .chargerConnectorCharging {
    background-color: $blue600;
    border: 2px solid darken($blue600, 15%);
  }

  .chargerConnectorChargingActive:before {
    background-color: $blue600;
    box-sizing: border-box;
  }

  .chargerConnectorChargingActiveText {
    color: white;
    text-shadow: 0 0 4px white, 0 0 18px white;
  }

  .chargerConnectorChargingInactive {
    background-color: $blue600;
    border: 2px solid darken($blue600, 15%);
  }

  .chargerConnectorFaulted {
    background-color: $red600;
    border: 2px solid darken($red600, 15%);
  }

  .chargerConnectorUnavailable {
    background-color: $grey600;
    border: 2px solid darken($grey600, 15%);
  }

  .chargerConnectorSuspendedEvse {
    background-color: $blue600;
    border: 2px solid darken($blue600, 15%);
  }

  .chargerConnectorSuspendedEv {
    background-color: $blue600;
    border: 2px solid darken($blue600, 15%);
  }

  .chargerConnectorPreparing,
  .chargerConnectorFinishing {
    background-color: $orange600;
    border: 2px solid darken($orange600, 15%);
  }

  .chargerConnectorReserved {
    background-color: $orange600;
    border: 2px solid darken($orange600, 15%);
  }

  .chargerConnectorUnknown {
    background-color: $grey600;
    border: 2px solid darken($grey600, 15%);
  }
}

@media screen and (max-width: 1366px) {
  .detailConnector {
    min-width: 38px;
  }
  .chargerConnectorContainer {
    width: 22px;
    height: 22px;

    &.chargerConnectorContainerImage {
      width: 20px;
      height: 20px;
    }
  }
}