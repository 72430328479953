.nav {
    margin-top: 0px;
    margin-left: 15px;
    display: block;
    overflow: auto;

    &Primary {
        // height: calc(100vh - 196px);  - this line is required if navigation menu items is added more than 11
    }

    &Item {
        margin-top: 5px !important;
        &Link {
            display: flex;
            color: #545A6D;
            padding: 12px 20px;
            text-transform: capitalize;
            font-size: 14px;

            &:hover {
                background-color: #F4F4F4;
                color: #242424;
                border-radius: 4px 0px 0px 4px;
            }

            &Active {
                background-color: #3D3AD3 !important;
                color: #fff !important;
                // font-size: 18px !important;
                border-radius: 4px 0px 0px 4px;
                display: flex;
                align-items: center;
            }
        }

        &Icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            line-height: 30px;
            width: 30px;
        }

        &Text {
            line-height: 24px;
            // font-size: 14px;
            position: relative;
            display: block;
            height: auto;
            white-space: nowrap;
            margin-left: 12px;
        }
    }
}

@media screen and (max-width:1900px) {
    .nav {
        margin-top: 0px;
        margin-left: 10px;

        &Item {
            margin-top: 15px !important;
            &Link {
                padding: 8px 10px;
                font-size: 12px;
                line-height: 14px;
            }

            &Icon {
                font-size: 20px;
                line-height: 20px;
                width: 20px;
            }

            &Text {
                margin-top: 3px;
                line-height: 14px;
                margin-left: 16px;
            }
        }
    }

    .navItemIconWidth {
        font-size: 20px !important;
    }
}

@media screen and (max-width:1700px) {
    .nav {
        margin-top: 0px;
        margin-left: 10px;

        &Item {
            margin-top: 7px !important;
            &Link {
                padding: 8px 10px;
                font-size: 12px;
                line-height: 14px;
            }

            &Icon {
                font-size: 20px;
                line-height: 20px;
                width: 20px;
            }

            &Text {
                margin-top: 3px;
                line-height: 14px;
                margin-left: 16px;
            }
        }
    }

    .navItemIconWidth {
        font-size: 20px !important;
    }
}

@media screen and (max-width:1600px) {
    .nav {
        margin-top: 0px;
        margin-left: 10px;

        &Item {
            margin-top: 3px !important;
            &Link {
                padding: 8px 10px;
                font-size: 12px;
                line-height: 14px;
            }

            &Icon {
                font-size: 20px;
                line-height: 20px;
                width: 20px;
            }

            &Text {
                margin-top: 3px;
                line-height: 14px;
                margin-left: 16px;
            }
        }
    }

    .navItemIconWidth {
        font-size: 20px !important;
    }
}

@media screen and (max-width:1366px) {
    .nav {
        margin-top: 0px;
        margin-left: 9px;

        &Item {
            margin-top: 1.5px !important;
            &Link {
                padding: 6.8px 8.5px;
                font-size: 10.2px;
                line-height: 12px;
            }

            &Icon {
                font-size: 17px;
                line-height: 17px;
                width: 17px;
            }

            &Text {
                margin-top: 2px;
                line-height: 12px;
                margin-left: 12px;
            }
        }
    }

    .navItemIconWidth {
        font-size: 17px !important;
    }
}