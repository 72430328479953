.notification{
    &:global(.MuiAlert-root){
       
        color: #fafafa;
        padding: 20px 15px 20px 66px;
        width: 430px;
        box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(56 142 60 / 40%);
    }

    &:global(.MuiAlert-standardSuccess){
        background-color: #3c9941;
    }

    &:global(.MuiAlert-standardError){
        background-color: #d63c3c;
    }
    &:global(.MuiAlert-standardInfo){
        background-color: #309df4;
    }
    &:global(.MuiAlert-standardWarning){
        background-color: #FF8405;
    }

    :global(.MuiAlert-icon){
        display: block;
        height: 38px;
        width: 38px;
        left: 15px;
        position: absolute;
        margin-top: -39px;
        font-size: 20px;
        background-color: #fafafa;
        padding: 9px;
        border-radius: 50%;
        max-width: 38px;
        opacity: 1;
        box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
    }

    &Message{
        color: #fff;
        padding: 0;
    }
}