@import './../../../../assets/scss/base/base.scss';
.multiselect {
  position: relative;
  &Input {
    &Item {
      position: relative;
      padding-top: 8px !important;
      padding-bottom: 9px !important;

      &:global(.MuiButtonBase-root.Mui-selected),
      &:global(.MuiButtonBase-root.Mui-selected:hover),
      &:global(.MuiButtonBase-root.Mui-selected:focus) {
        background-color: #eeeefa;
        // color: #fff;
      }
      :global(.MuiButtonBase-root.MuiCheckbox-root.Mui-checked) {
        color: #fff;
      }

      :global(.MuiButtonBase-root.MuiCheckbox-root) {
        padding-left: 0;
      }

      :global(.MuiTypography-root) {
        font-size: 15.2px;
      }
    }
    :global(.MuiSvgIcon-root) {
      color: rgb(0, 0, 0, 0.87);
    }
  }

  &Filter {
    :global(.MuiFormLabel-root),
    :global(.MuiFormLabel-root.Mui-focused) {
      color: #000;
    }

    :global(.MuiInputBase-root:after) {
      border-bottom-color: #000;
    }

    :global(.MuiInputBase-root:not(.Mui-disabled, .Mui-error):before),
    :global(.MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before) {
      border-bottom: 1px solid #000 !important;
    }
  }
}

:global(.MuiList-root.MuiMenu-list) {
  padding: 0;
  min-width: calc(100% + 64px);
  max-width: 545px;
  max-height: 50vh !important;
}

@media screen and (max-width: 1440px) {
  :global(.MuiList-root.MuiMenu-list) {
    padding: 0;
    min-width: calc(100% + 284px);
    max-width: 285px;
    max-height: 50vh !important;
  }
}

:global(.statics_Menu_item) {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 450px;
  display: block !important;
}

:global(.MuiInputBase-input.MuiInput-input) {
  height: 1.4375em !important;
}
@media screen and (max-width: 1440px) {
  .inputLabel {
    font-size: 12px;
  }
  .multiselect{
    &Input {
      &Item {
        position: relative;
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    
        :global(.MuiTypography-root) {
          font-size: 12px;
        }
      }
     
    }
  }

}

@media screen and (max-width: 1200px) {
  .inputLabel {
    font-size: 12px !important;
  }

}
