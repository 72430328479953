@import './../../../../assets/scss/base/base.scss';

.card {
  background: rgba(25, 118, 210, 0.1);
  padding-bottom: 11px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 14%);

  &Header {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }

  &Icon {
    border-radius: 5px;
    color: #fafafa;
    background-color: $brand-primary;
    padding: 15px;
    margin-top: -10px;
    margin-right: 15px;

    img {
      height: 24px;
      width: 14px;
    }
  }

  &Title {
    font-size: 24px;
  }
  &Body {
    padding: 12px 30px;
  }

  &Transparent {
    background-color: #fff;
  }
}

@media screen and (max-width: 1440px) {
  .card {
    &Icon {
      padding: 10px;
    }
  }
}
