@import './../../../../assets/scss/base/base.scss';

.button {
  &:global(.MuiButtonBase-root) {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    background-color: $brand-primary;
    color: #ffffff;
    box-shadow: 2px 2px 8px 1px rgba(16, 24, 40, 0.12);
    &:disabled {
      background-color: rgba(61, 58, 211, 0.2);
    }

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0.2) 100%
        ),
        #3d3ad3;
    }

    &:focus {
      background-color: $brand-primary;
    }
  }

  @include lg-device() {
    &:global(.MuiButtonBase-root) {
      min-width: 0 !important;
      min-height: 0 !important;
      padding: 8px 10.5px;
      font-size: 10px;
    }
    & svg {
      width: 13px;
      height: 13px;
    }
    img {
      width: 13px;
      height: 13px;
    }
  }

  @include xl-device() {
    &:global(.MuiButtonBase-root) {
      min-width: 0 !important;
      min-height: 0 !important;
      padding: 10px 12.5px;
      font-size: 12px;
    }
    & svg {
      width: 15px;
      height: 15px;
    }
    img {
      width: 15px;
      height: 15px;
    }
  }

  @include xxl-device() {
    &:global(.MuiButtonBase-root) {
      min-width: 0 !important;
      min-height: 0 !important;
      padding: 12px 15px;
      font-size: 13px;
    }
    & svg {
      width: 18px;
      height: 18px;
    }
    img {
      width: 18px;
      height: 18px;
    }
  }

  @include seventeen-down-device() {
    &:global(.MuiButtonBase-root) {
      min-width: 0 !important;
      min-height: 0 !important;
      padding: 16px 20px;
      font-size: 15px;
    }
    & svg {
      width: 24px;
      height: 24px;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }

  @include nineteen-down-device() {
    &:global(.MuiButtonBase-root) {
      min-width: 0 !important;
      min-height: 0 !important;
      padding: 16px 20px;
      font-size: 15px;
    }
    & svg {
      width: 24px;
      height: 24px;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
}
