.main{
    display: flex;
    height: 100vh;

    &Body{
        position: relative;
        flex-grow: 1;
        width: calc(100% - 80px);
        overflow: auto;
        &Shrink{
            width: calc(100% - 260px);
        }
    }
}
