@import './../../../../assets/scss/base/base.scss';
.select {
  &Item {
    display: flex;
    align-items: center;
    &Img {
      align-items: center;
      display: inline-flex;
      margin-right: 16px;

      :global(img) {
        height: 20px;
        width: 20px;
      }
    }
    &:global(.MuiMenuItem-root.Mui-selected) {
      background-color: $brand-primary !important;
      color: #fff;
      min-width: 100px;
    }

    @media screen and (max-width: 1440px) {
      &:global(.MuiMenuItem-root.Mui-selected) {
        font-size: 12px;
      }
      &:global(.MuiMenuItem-root) {
        font-size: 12px;
      }
    }
    &Label {
    }
  }

  :global(.MuiSelect-select) {
    display: flex;
    align-items: center;
  }
}
