@import './../../../../assets/scss/base/base.scss';
.tile{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    &Img{
       height: 58px;
       width: 58px;
       overflow: hidden;
       border-radius: 100%;
       margin-bottom: 16px;
       img{
        height: 100%;
        width: 100%;
        object-fit: cover;
       }
    }

    &Icon{

    }
    &Title{
        font-size: 16px;
    }
    &SubTitle{
        font-size: 14px;
    }

    &_Success{
        color: $brand-success;
    }
}