
.containerStyles {
    max-width: calc(100vw - 280px);
    position: relative;

   @media screen and (max-width:1720px){
      max-width: calc(100vw - 272px);
   }

   @media screen and (max-width:1600px){
      max-width: calc(100vw - 225px);
   }

   @media screen and (max-width:1440px){
      max-width: calc(100vw - 225px);
   }
   
   @media screen and (max-width:1366px){
      max-width: calc(100vw - 185px);
   }
}