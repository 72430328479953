@import './../../../../assets/scss/base/base.scss';

.datatable {
  background-color: #fff;

  &Wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    > :global(.MuiTableContainer-root) {
      flex-grow: 1;
      background-color: #fff;
    }
  }

  &PriceWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #fff;

    > :global(.MuiTableContainer-root) {
      flex-grow: 1;
      background-color: #fff;
    }
  }

  &Header {
    th {
      border-bottom-width: 1px;
      position: sticky;
      top: 0px;
      background: #fafafa;
      z-index: 100;
      font-size: 0.95rem;
      padding: 5px 8px 5px 8px;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      :global(.MuiCheckbox-root.MuiButtonBase-root) {
        padding: 0;
      }
    }

    // &Cell{
    //     display: flex;
    // }
  }

  &PriceHeader {
    th {
      border-bottom-width: 1px;
      position: sticky;
      top: 0px;
      background: #ededed !important;
      z-index: 100;
      font-size: 0.95rem;
      padding: 5px 8px 5px 8px;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      :global(.MuiCheckbox-root.MuiButtonBase-root) {
        padding: 0;
      }
    }

    // &Cell{
    //     display: flex;
    // }
  }

  &Body {
    background: #fff;
   
    tr {
      height: 38px;
      background-color: #fff;

      td {
        //   border-right: 1px solid #EAECF0;
      }

      &:nth-child(even) {
        background: #fafafa;

        td {
          //   border-right: 1px solid #fafafa;
        }
      }

      td {
        padding: 8px;
        text-align: left;
        font-size: 15.2px;
        // min-height: 41px;
        height:43px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        :global(.MuiCheckbox-root.MuiButtonBase-root) {
          padding: 0;
        }
      }
    }
  }

  &EmptyMessage {
    height: 100% !important;

    &Cell {
      text-align: center !important;
      // border-bottom: none !important;
    }
  }

  &Expand {
    text-align: center;
  }

  &Cell {
    &Action {
      padding: 0 !important;
      padding-left: 4px !important;
      &:global(.MuiTableCell-root) {
        display: flex;
        align-items: center;
        justify-content: center;
        // height: 33px;
      }
    }

    &Expand {
      padding: 0 !important;
      width: 43px;
    }

    &TextWithIcon {
      display: inline-flex;
      align-items: center;

      &Success {
        color: #388e3c;
      }

      &Error {
        font-weight: 500;
        color: #d32f2f;
      }

      &_Text {
      }

      &_Icon {
        display: inline-flex;
        align-items: center;
        margin-right: 10px;
      }
    }

    &Connector {
      display: flex;
      align-items: center;
      width: 30px;
      height: 30px;
      z-index: 1;
      color: #fff;

      &Text {
        display: inline-flex;
        background-color: #757575;
        // border: 2px solid #4f4f4f;
        border-radius: 100%;
        width: 30px;
        min-width: 30px;
        height: 30px;
        font-size: 1.1rem;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        z-index: 1;
        color: #fff;

        &Success {
          color: #43a047;
          border: 2px solid #3b8e3f;
          background-color: #fff;
        }
      }

      &Logo {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        border: 1px solid #757575;
        background-color: #a8a8a8;
        left: -15px;
        transition: all 0.2s ease-in-out;
        z-index: 0;
        position: relative;
        width: 28px;
        height: 28px;
        max-width: 28px;

        &:hover {
          transform: scale(1.5);
          z-index: 2;
        }
      }
    }
  }

  &Footer {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background: #fafafa;
    z-index: 100;

    &Left {
      display: flex;
      justify-content: flex-start;
    }

    &Right {
      display: flex;
      justify-content: flex-end;
    }
  }

  .datatable {
    .datatableHeader {
      tr {
        height: 27px;
      }
    }
  }
}

.tfooterRecords {
  height: 25px;
  width: 100%;
  position: relative;
  margin-top: 25px;
}

.tableCpntroller {
  max-height: calc(68vh - 25px);
}


.button {
  &:global(.MuiButtonBase-root) {
    text-transform: capitalize;
    font-weight: 500;
    margin: 0px 0px 0px 4px;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    background-color: $brand-primary;
    color: #ffffff;
    box-shadow: 2px 2px 8px 1px rgba(16, 24, 40, 0.12);

    &:disabled {
      color: rgba(255, 255, 255, 0.26);
    }

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0.2) 100%
        ),
        #3d3ad3 !important;
    }

    &:focus {
      background-color: $brand-primary;
    }
  }
}

@media screen and (min-width: 650px) and (max-width: 1000px) {
  .tableCpntroller {
    height: 64vh !important;
  }
}

@media screen and (min-height: 700px) and (max-height: 800px) {
  .tableCpntroller {
    height: 61vh !important;
  }
}

@media screen and (min-height: 600px) and (max-height: 700px) {
  .tableCpntroller {
    height: 58vh !important;
  }
}

.autoRefresh {
  @include xl-device() {
    display: flex;
    gap: 10px;
  }

  @include xxl-device() {
    display: flex;
    gap: 14px;
  }

  @include seventeen-down-device() {
    display: flex;
    gap: 16px;
  }
  @include nineteen-down-device() {
    display: flex;
    gap: 16px;
  }
}

@media screen and (max-width: 1720px) {
  .datatable {
    &Header {
      th {
        font-size: 14px;
      }
    }

    &Body {
      tr {
        td {
          font-size: 14px;
          // min-height: 38px !important;
          padding: 0px 8px 0px 8px !important;
        }
      }
    }

    &Row {
      height: 38px !important;
    }

    &Res {
      height: 45px !important;
      gap: 5px !important;
    }

    &Button {
      padding: 0px !important;
    }
  }
}

@media screen and (max-width: 1600px) {
  .datatable {
    &Header {
      th {
        font-size: 12px;
      }
    }

    &Body {
      tr {
        td {
          font-size: 12px;
          // min-height: 36px !important;
          height: 38px !important;
          padding: 0px 8px 0px 8px !important;
        }
      }
    }

    &Row {
      height: 36px !important;
    }

    &Res {
      height: 36px !important;
      gap: 4.5px !important;
    }

    &Button {
      padding: 0px !important;
    }

    &Icon {
      padding: 0px;
      width: 18px !important;
      height: 18px !important;
    }
  }
}

@media screen and (max-width: 1440px) {
  .datatable {
    &Header {
      th {
        font-size: 10px;
      }
    }

    &Body {
      tr {
        td {
          font-size: 10px;
          // min-height: 30px !important;
          height: 38px !important;
          padding: 0px 8px 0px 8px !important;
        }
      }
    }
    &datatableCellTextWithIcon_Icon {
      height: 10px !important;
    }
    &Row {
      height: 30px !important;
    }

    &Res {
      height: 24px !important;
      gap: 5px !important;
    }

    &Button {
      padding: 0px !important;
      height: 24px !important;
    }

    &Icon {
      padding: 0px;
      width: 16px !important;
      height: 16px !important;
    }

    &KeyIcon {
      width: 18px !important;
      height: 18px !important;
    }

    &Cell {
      &TextWithIcon {
        font-size: 12px !important;
        height: 16px !important;
        display: flex !important;
        align-items: center !important;
        &_Icon {
          height: 14px !important;
          display: flex !important;
          align-items: center !important;
        }
      }

      &Connector {
        width: 22px !important;
        height: 22px !important;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .datatable {
    &Header {
      th {
        font-size: 8px;
      }
    }

    &Body {
      tr {
        td {
          font-size: 10px;
          // min-height: 30px !important;
          height: 38px !important;
          padding: 0px 8px 0px 8px !important;
        }
      }
    }
    &datatableCellTextWithIcon_Icon {
      height: 8px !important;
    }
    &Row {
      height: 28px !important;
    }

    &Res {
      height: 22px !important;
      gap: 3px !important;
    }

    &Button {
      padding: 0px !important;
      height: 22px !important;
    }

    &Icon {
      padding: 0px;
      width: 14px !important;
      height: 14px !important;
    }

    &KeyIcon {
      width: 16px !important;
      height: 16px !important;
    }

    &Cell {
      &TextWithIcon {
        font-size: 10px !important;
        height: 14px !important;
        display: flex !important;
        align-items: center !important;
        &_Icon {
          height: 12px !important;
          display: flex !important;
          align-items: center !important;
        }
      }

      &Connector {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}
