@import './../../../../assets/scss/base/base.scss';

.button {
  &:global(.MuiButtonBase-root) {
    text-transform: capitalize;
    font-weight: 500;
    margin: 0px 0px 0px 4px;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    background-color: $brand-primary;
    color: #ffffff;
    box-shadow: 2px 2px 8px 1px rgba(16, 24, 40, 0.12);

    &:disabled {
      background-color: rgba(61, 58, 211, 0.2);
      color: rgba(0, 0, 0, 0.7);
    }

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0.2) 100%
        ),
        #3d3ad3;
    }

    &:focus {
      background-color: $brand-primary;
    }
  }
}

@media screen and (max-width: 1440px) {
  .button {
    &:global(.MuiButtonBase-root) {
      text-transform: capitalize;
      font-weight: 500;
      margin: 0px 0px 0px 4px;
      font-size: 12px;
      border-radius: 4px;
      border: none;
      background-color: $brand-primary;
      color: #ffffff;
      box-shadow: 2px 2px 8px 1px rgba(16, 24, 40, 0.12);
      &:disabled {
        background-color: rgba(61, 58, 211, 0.2);
        color: rgba(0, 0, 0, 0.7);
      }

      &:hover {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0.2) 100%
          ),
          #3d3ad3;
      }
      & svg {
        width: 15px;
        height: 15px;
      }
      img {
        width: 15px;
        height: 15px;
      }

      &:focus {
        background-color: $brand-primary;
      }
    }
  }
}
