.graphContainer {
    height: 400px;
    width: 100% !important;
    overflow-x: auto;
}


@media screen and (max-width: 1600px) {
    .graphContainer {
        height: 300px;
        width: 100% !important;
    }
}