.chip {
  position: relative;
  border-width: 2px;
  font-size: 12px;
  font-weight: bold;
  padding: 7px 12px;
  border-radius: 16px;
  align-items: center;
  cursor: default;
  min-height: 32px;
  &:global(.Mui-disabled.MuiChip-root) {
    color: rgba(0, 0, 0, 0.38);
    border-color: rgba(0, 0, 0, 0.38);
    opacity: 1;
    padding-left: 10px;
    padding-right: 10px;
    border-width: 2px;
  }
      // EVES-1061 updated chip styles to fit content up to 15 chars

  &:global(.MuiChip-root) {
    border-width: 2px;
    font-size: 0.9rem;
    justify-content: center;
    padding: 7px 0px;
    margin: 4px;
    max-width: 10rem;
  }
}

@media screen and (max-width: 1600px) {
  .chip {
    min-height: 25px !important;
    &:global(.MuiChip-root) {
      height: 25px !important;
      font-size: 12px;
            // EVES-1061 updated chip styles to fit content up to 15 chars
      max-width: 9rem;
    }
  }
}

@media screen and (max-width: 1366px) {
  .chip {
    min-height: 20px !important;
    &:global(.MuiChip-root) {
      height: 20px !important;
      font-size: 11px;
      // EVES-1061 updated chip styles to fit content up to 15 chars
      max-width: 8rem;
    }
  }
}
