@import './../../../../assets/scss/base/base.scss';
.register {
  min-height: 1100px;
  height: auto;
  display: inherit;
  background-image: url('./../../../../assets/img/illustration.svg');
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &Title {
    text-align: center;
    font-weight: 500;
    margin: 20px 0 40px 0;
    font-size: 15px;
    color: #000000;
  }

  &Card {
    position: absolute;
    top: 10%;
    left: 30%;
    padding: 30px;
    max-width: 647px;
    width: 647px;
    background: #fafafa;
    opacity: 0.9;
    z-index: 2;
    animation: SlideDown 0.6s ease-in-out;

    &Header {
      display: flex;
      justify-content: center;
    }
  }

  &Form {
    &Row {
      margin-bottom: 20px;
    }

    &Control {
      display: flex;
      justify-content: space-around;

      gap: 0.5rem;
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 1440px) {
    &Title {
      text-align: center;
      font-weight: 500;
      margin: 10px 0 20px 0;
      font-size: 13px;
      color: #000000;
    }
    &Form {
      &Row {
        margin-bottom: 10px;
      }
    }
    &ButtonSignup {
      margin: 40px 0 20px 0;
      font-size: 12px;
    }
    &ButtonForgot {
      font-size: 12px;
    }
    &Agreement {
      &Lbl {
        :global(.MuiTypography-root) {
          font-size: 12px;
        }
      }
    }
  }

  &ButtonSignin {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    :global(.MuiButton-root.MuiButtonBase-root) {
      text-transform: inherit;
    }
  }

  &ButtonSignup {
    margin: 80px 0 40px 0;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }

  &ButtonForgot {
    font-size: 14px;
  }

  &Agreement {
    display: flex;
    justify-content: center;
    &Lbl {
      :global(.MuiTypography-root) {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1100px;
  }

  &::before {
    background: rgba(0, 0, 0, 0.3);
  }

  &CenterRegBtn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  &IconInvisible {
    opacity: 0;
  }
}

@keyframes SlideDown {
  0% {
    transform: translateY(-250px);
  }

  100% {
    transform: translateY(0px);
  }
}
.signup_icons {
  margin-top: 22px;
}
.recaptcha_text {
  margin-top: 15px;
  padding: 0 5%;
  font-size: small;
  color: #999;
  line-height: 22px;
}
