.labelTextSelect {
    color: var(--Gray-900, #101828);
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


@media screen and (max-width: 1720) {
    .labelTextSelect {
        font-size: 22px;
    }
}

@media screen and (max-width: 1500px) {
    .labelTextSelect {
        font-size: 20px;
    }
}

@media screen and (max-width: 1600px) {
    .labelTextSelect {
        font-size: 18px;
    }
}

@media screen and (max-width: 1400px) {
    .labelTextSelect {
        font-size: 16px;
    }
}