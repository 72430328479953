.commonCardContainer {
    height: 68px;
    padding: 20px 16px;
    border-radius: 8px;
    gap: 12px;
    box-shadow: 0px 0px 4px 0px #1018281A;
    border: 1px solid #C2C2C2;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #C2C2C2, #C2C2C2);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.commonCardHeaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.commonCardHeaderText {
    color: var(--Gray-900, #101828);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.commonCardHeaderDescText {
    color: var(--Gray-900, #101828);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.commonCardImage {
    width: 28px;
}

.commonCardFooterText {
    color: var(--Gray-900, #101828);
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.charginConfigFooter{
    color: var(--Gray-900, #101828);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


@media screen and (max-width: 1720px) {
    .commonCardContainer {
        height: 70px;
        gap: 6px;
    }

    .commonCardHeaderText {
        font-size: 14px;
    }

    .commonCardHeaderDescText {
        font-size: 12px;
    }

    .commonCardImage {
        width: 28px;
    }

    .commonCardFooterText {
        font-size: 22px;
    }
    .charginConfigFooter{
        font-size: 18px;
    }
}


@media screen and (max-width: 1600px) {
    .commonCardContainer {
        height: 50px;
        gap: 6px;
    }

    .commonCardHeaderText {
        font-size: 12px;
    }

    .commonCardHeaderDescText {
        font-size: 10px;
    }

    .commonCardImage {
        width: 24px;
    }

    .commonCardFooterText {
        font-size: 18px;
    }
    .charginConfigFooter{
        font-size: 12px;
    }
}


@media screen and (max-width: 1440px) {
    .commonCardContainer {
        height: 50px;
        gap: 6px;
    }

    .commonCardHeaderText {
        // width:80%;
        font-size: 10px;
    }

    .commonCardHeaderDescText {
        font-size: 8px;
    }

    .commonCardImage {
        width: 22px;
    }

    .commonCardFooterText {
        font-size: 16px;
    }
    .charginConfigFooter{
        font-size: 14px;
    }
}

@media screen and (max-width: 1280px) {
    .commonCardContainer {
        height: 50px;
        gap: 4px;
    }

    .commonCardHeaderText {
        // width:80%;
        font-size: 10px;
    }

    .commonCardHeaderDescText {
        font-size: 8px;
    }

    .commonCardImage {
        width: 22px;
    }

    .commonCardFooterText {
        font-size: 16px;
    }
    .charginConfigFooter{
        font-size: 14px;
    }
}