@import './../../../../assets/scss/base/base.scss';
.signin {
  min-height: 100vh;
  height: auto;
  display: inherit;
  background-image: url('./../../../../assets/img/illustration.svg');
  width: 100%;
  // height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  

  &Card {
    position: absolute;
    left: 380px;
    top:100px !important;    
    width: 465px;
    background: #fafafa;
    opacity: 0.9;
    z-index: 2;
    transform: translate3d(0, 0, 0);
    animation: SlideDown $general-transition-time;

    &Header {
      display: flex;
      justify-content: center;
    }
  }



 

 
  @media screen and (max-width: 1440px) {
    &ButtonSignup {
      margin: 20px 0 20px 0;
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;
    }
    &Title {
      text-align: center;
      font-weight: 500;
      margin: 10px 0 20px 0;
      font-size: 16px;
    }
    &ButtonForgot {
      font-size: 12px;
    }
    &Agreement {
      display: flex;
      justify-content: center;
      &Lbl {
        :global(.MuiTypography-root) {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }
  }
  @media screen and (max-width: 1360px) {
    &Card {
      position: absolute;
      top: 32px;
    }
    .logo {
      height: 64px;
      width: 64px;
    }
  }

  &ButtonForgot {
    font-size: 14px;
  }

  &Agreement {
    display: flex;
    justify-content: center;
    &Lbl {
      :global(.MuiTypography-root) {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &::before {
    background: rgba(0, 0, 0, 0.3);
  }
}

@keyframes SlideDown {
  0% {
    transform: translate3d(0, -60px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}
