@import './../../../../assets/scss/base/base.scss';

.checkbox {
    &:global(.MuiCheckbox-root) {
        padding: 2px 9px;
        color: $brand-primary !important;
    }

    &:global(.MuiCheckbox-root.Mui-disabled) {
        padding: 2px 9px;
        color: $disable-primary !important;
    }

    + :global(.MuiFormControlLabel-label) {
        line-height: 24px;
        font-size: 14px;
    }
}

// @media screen and (max-width: 1366px) {
//     .checkbox{
//         width: 16px !important;
//         height: 16px !important;
//         &:global(.MuiCheckbox-root) {
//             padding: 0px 9px;
//         }
    
//         &:global(.MuiCheckbox-root.Mui-disabled) {
//             padding: 0px 9px;
//         }
//     }

// }