.geomap {
  &Header {
    margin-bottom: 15px;
  }

  &Body {
    height: calc(100vh - 270px);
    overflow-x: auto;
    overflow-y: auto;
    max-height: none;
  }

  @media screen and (max-width: 1440px) {
   
  &Body {
    height: calc(100vh - 340px);
    overflow-x: auto;
    overflow-y: auto;
    max-height: none;
  }
  }

  &Footer {
    padding-top: 15px;
    display: flex;
    justify-content: flex-end;
    align-self: self-end;
    position: sticky;
    bottom: 0;
    z-index: 100;
    align-items: center;
    width: 100%;
  }
}
