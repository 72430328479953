.userAccount {
  padding: 0px 16px;
  display: flex;
  align-items: center;
  height: 52px;
  gap: 8px;
  cursor: pointer;

  &:hover {
    background: #FFFFFF26;
    border-radius: 12px;
  }

  &Active {
    background: #2B28C2 !important;
    border-radius: 12px;
  }
}

.userProfileRole {
  font-size: 16px !important;
  font-weight: 500;
  line-height: 24px;
  color: #FFFFFF;
}

.userProfile {
  &Photo {
    height: 36px;
    width: 36px;
    overflow: hidden;
    float: left;
    z-index: 5;
    background: #FFFFFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      align-items: center;
      height: 20px;
      width: 20px;
      color: #737373;
    }
  }
}

.profileIcon{
  font-size: 20px !important;
}

.profileList {
  padding: 12px 16px !important;
  gap: 12px;
  display: flex;
  align-items: center;
}

.userProfileList {
  font-size: 16px;
  font-weight: 400;
  line-height: 24x;
  color: #000000;
}

.listContainer {
  border-radius: 0px !important;
}

@media screen and (max-width:1440px) {
  .userAccount {
    padding: 10px 12px;
    height: 33px;
    gap: 4.7px;
  
    &:hover {
      border-radius: 7.05px;
    }
    &Active {
      border-radius: 7.05px;
    }
  }
  
  .userProfileRole {
    font-size: 12px !important;
    font-weight: 500;
    line-height: 14.1px;
    color: #FFFFFF;
  }
  
  .userProfile {
    &Photo {
      height: 24px;
      width: 24px;
      img {
        height: 15px;
        width: 15px;
      }
    }
  }
  
  .profileList {
    padding: 7.05px 9.04px !important;
    gap: 4.07px;
  }
  
  .userProfileList {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.1px;
  }
}

@media screen and (max-width:1366px) {
  .userAccount {
    padding: 7.05px 9.4px;
    height: 31px;
    gap: 4.7px;
  
    &:hover {
      border-radius: 7.05px;
    }
    &Active {
      border-radius: 7.05px;
    }
  }
  
  .userProfileRole {
    font-size: 9.4px !important;
    font-weight: 500;
    line-height: 14.1px;
    color: #FFFFFF;
  }
  
  .userProfile {
    &Photo {
      height: 21.14px;
      width: 21.14px;
      img {
        height: 12px;
        width: 12px;
      }
    }
  }
  
  .profileIcon{
    font-size: 12px !important;
  }
  .profileList {
    padding: 7.05px 9.04px !important;
    gap: 4.07px;
  }
  
  .userProfileList {
    font-size: 9.4px;
    font-weight: 400;
    line-height: 14.1x;
  }
}