
@import './../../../../assets/scss/base/base.scss';

.switch {
   &:global(.Mui-checked.MuiSwitch-switchBase){
      color: #3D3AD3;
   }

   :global(.MuiSwitch-switchBase.Mui-checked){
      color: $brand-primary !important;
   }
}