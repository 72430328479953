@import './../../../../assets/scss/base/base.scss';

.gaps {
  display: flex;

  @include lg-device() {
    gap: 8px;
  }
  @include xl-device() {
    gap: 10px;
  }

  @include xxl-device() {
    gap: 12px;
  }

  @include seventeen-down-device() {
    gap: 16px;
  }
  @include nineteen-down-device() {
    gap: 16px;
  }
}
