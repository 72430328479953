.bodyContainer {
    // border-radius: 8px;
    // border: 1px solid var(--Foundation-Base-on-Light-base-on-light-3, #F5F5F5);
    background: var(--Base-White, #FFF);
}

.headerContainer {
    padding: 16px 20px;
    border-bottom: 1px solid #EEE;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dateText {
    color: var(--Gray-700, #344054);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.checkboxLabel {
    font-size: 12px;
    color: var(--root-base---on-light, #06070B);
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.checkboxStyles {
    width: 16px;
    height: 16px;
    background-color: #3D3AD3;
}

@media screen and (max-width: 1400px) {
    .headerContainer {
        padding: 8px 10px;
        border-bottom: 1px solid #EEE;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .dateText {
        color: var(--Gray-700, #344054);
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .checkboxLabel {
        font-size: 10px;
    }
}