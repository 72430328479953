.paymentGatewayForm{
    &Group{
        border-bottom: solid 1px #999;
        padding-bottom: 6px;
    }

    &Lbl{
        font-size: 14px;
        line-height: 1.42857;
        color: rgba(0, 0, 0, 0.87);
        font-weight: 400;
    }
    &Disclaimer{
        padding: 14px 22px;
        margin: 22px 0;
        background: #f57c00;
        line-height: 20px;
        font-size: 14.4px;
        color: #fafafa;
        text-align: justify;
    }
}