@import './../../../../assets/scss/base/base.scss';

.tabs {
    background-color: $brand-primary;
    padding: 12px 32px 0px 32px;
    height: 72px !important;
    width: 100%;
    display: flex;
    justify-content: space-between;

    :global(.MuiTab-root) {
        opacity: 1 !important;
        color: #FFFFFF;
        border: 0;
        margin: 0;
        height: 60px !important;
        min-width: 3em !important;
        border-radius: 12px 12px 0px 0px;
        line-height: 24px;
        font-size: 16px;
        display: flex;
        gap: 0px;
        padding: 18px 16px !important;
        background-color: transparent;
        transition: 0.3s background-color 0s;
        text-transform: uppercase;
        min-height: auto;
        font-weight: 500;
        letter-spacing: normal;

        // margin-top: 12px;
        &:hover {
            background-color: rgba(255, 255, 255, 0.15);
        }
    }

    :global(.MuiTab-root.Mui-selected) {
        background-color: #fafafa;
        color: $brand-primary;
    }

    :global(.MuiTabs-indicator) {
        display: none;
    }

    &Actions {
        flex-grow: 1;
        justify-content: flex-end;
        display: flex;
        color: #fff;
    }

    :global(.MuiTabs-flexContainer) {
        gap: 0px;
        // padding-top: 6px;
    }

    .tabsItem {
        gap: 8px;
        line-height: 24px !important;
        :global(.MuiSvgIcon-root) {
            font-size: 20px;
            margin: 0px !important;
        }
    }
   
}

.tabsIcon {
    background-color: $brand-primary;
    padding: 12px 32px 0px 32px;
    height: 72px !important;
    width: 100%;
    display: flex;
    justify-content: space-between;

    :global(.MuiTab-root) {
        opacity: 1 !important;
        color: #FFFFFF;
        border: 0;
        margin: 0;
        height: 60px !important;
        min-width: 3em !important;
        border-radius: 12px 12px 0px 0px;
        line-height: 24px;
        font-size: 16px;
        display: flex;
        gap: 0px;
        padding: 18px 16px !important;
        background-color: transparent;
        transition: 0.3s background-color 0s;
        text-transform: uppercase;
        min-height: auto;
        font-weight: 500;
        letter-spacing: normal;

        // margin-top: 12px;
        &:hover {
            background-color: rgba(255, 255, 255, 0.15);
        }
    }

    :global(.MuiTab-root.Mui-selected) {
        background-color: #fafafa;
        color: $brand-primary;
    }

    :global(.MuiTabs-indicator) {
        display: none;
    }

    &Actions {

        flex-grow: 1;
        justify-content: flex-end;
        display: flex;
        color: #fff;
    }

    :global(.MuiTabs-flexContainer) {
        gap: 0px;
        padding-top: 6px;
    }

    .tabsItem {
        gap: 8px;
        line-height: 24px !important;
        :global(.MuiSvgIcon-root) {
            font-size: 20px;
            margin: 0px !important;
        }
    }
   
}

.userAccount {
    padding: 12px 16px;
    &:hover {
        background: #FFFFFF26;
    }
}

@media screen and (max-width:1440px) {
    .tabs,.tabsIcon {
        background-color: $brand-primary;
        padding: 10px 18px 0px 18px;
        height: 52px !important;
        width: 100%;


      :global(.MuiTab-root) {
          opacity: 1 !important;
          color: #FFFFFF;
          border: 0;
          margin: 0;
          height: 42px !important;
          min-width: 3em !important;
          border-radius: 7.05px 7.05px 0px 0px;
          line-height: 14.1px;
          font-size: 12px;
          display: flex;
          gap: 0px;
          padding: 10px 9.4px !important;
      }
  }

  .userAccount {
      padding: 0px;
  }

  .tabsItem {
      gap: 4.7px !important;

      :global(.MuiSvgIcon-root) {
          font-size: 14px !important;
          margin: 0px !important;
      }
  }
}

@media screen and (max-width:1300px) {
    .tabs,.tabsIcon  {
        background-color: $brand-primary;
        padding: 8px 18.79px 0px 18.79px;
        height: 43px !important;
        width: 100%;
        display: flex;
        justify-content: space-between;

        :global(.MuiTab-root) {
            opacity: 1 !important;
            color: #FFFFFF;
            border: 0;
            margin: 0;
            height: 35px !important;
            min-width: 3em !important;
            border-radius: 7.05px 7.05px 0px 0px;
            line-height: 14.1px;
            font-size: 9.4px;
            display: flex;
            gap: 0px;
            padding: 10px 9.4px !important;
            background-color: transparent;
            transition: 0.3s background-color 0s;
            text-transform: uppercase;
            min-height: auto;
            font-weight: 500;
            letter-spacing: normal;
        }
    }

    .userAccount {
        padding: 12px 16px;
    }

    .tabsItem {
        gap: 4.7px !important;

        :global(.MuiSvgIcon-root) {
            font-size: 12px !important;
            margin: 0px !important;
        }
    }
}

